@font-face {
  font-family: "Roboto Medium";
  src: url("./assets/fonts/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto Regular";
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto Light";
  src: url("./assets/fonts/Roboto/Roboto-Light.ttf");
}

@font-face {
  font-family: "Roboto Italic";
  src: url("./assets/fonts/Roboto/Roboto-Italic.ttf");
}

@font-face {
  font-family: "Roboto Thin";
  src: url("./assets/fonts/Roboto/Roboto-Thin.ttf");
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1 {
  color: indianred;
  margin: 0;
}

h2 {
  font-family: "Roboto Medium";
  font-size: 25px;
  color: #3a3a3a;
  margin: 0;
}

h2 {
  color: #3a3a3a;
  text-decoration: none;
}

h2 > a:hover {
  text-decoration: underline;
}

ul {
  margin-top: 5px;
  margin-bottom: 0;
}

li {
  list-style-type: none;
  color: #3a3a3a;
  font-family: "Roboto Thin";
}

li:not(:first-child) {
  margin-top: 3px;
}

.name-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.firstname {
  font-family: "Roboto Thin";
  color: gray;
}

.lastname {
  font-family: "Roboto Medium";
  color: #3a3a3a;
}

.lastname,
.firstname {
  font-size: 42px;
}

.position {
  font-variant: small-caps;
  letter-spacing: 0.6px;
  color: darkred;
}

.info-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.address {
  margin-top: 5px;
  color: darkslateblue;
}

.subinfo-container a {
  text-decoration: none;
  color: gray;
}

.subinfo-container > a:hover {
  text-decoration: underline;
}

.subinfo-label {
  margin-left: 5px;
}

.subinfo {
  display: flex;
  align-items: center;
}

.title-container {
  width: 100%;
  display: flex;
  margin-top: 20px;
}

.bottom-line {
  flex: 1;
  margin-left: 10px;
  border-bottom: 2px solid indianred;
  margin-bottom: 8px;
}

.item-container {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.item-description {
  display: flex;
  flex-direction: column;
}

.item-information {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.item-title {
  font-family: "Roboto Medium";
  font-size: 25px;
  margin: 0;
}

.item-details {
  margin-top: 10px;
  font-variant: small-caps;
  letter-spacing: 0.6px;
  color: grey;
  text-decoration: none;
}

.item-location {
  font-size: 20px;
  color: indianred;
}

.item-date {
  margin-top: 5px;
  font-family: "Roboto Italic";
  color: darkslateblue;
}
